/* TODO: Setup mixins here */

*, *:before, *:after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}


::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c6c5c5;
}
::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
}

html {

    --primary-background-color: #2961fd;
    --secondary-background-color: #e9ebf2;
    --primary-icon-color: white;
    --secondary-icon-color: rgba(0, 0, 0, 0.8);
    
    /* Text colors */

    --primary-text-color: #000000;

    /* Button colors */


    /* Primary Button */
    --primary-button-text-color: white;
    --primary-button-color: #2961fd;
    --primary-button-hover-color: #0a4bfc;
    --primary-button-active-color: #2252d6;
    --primary-button-deactivated-color: rgba(44, 91, 255, 0.5);

    /* Danger Button */
    --danger-button-text-color: white;
    --danger-button-color: #d0021b;
    --danger-button-hover-color: #eb021d;
    --danger-button-active-color: #b80217;
    --danger-button-deactivated-color: rgba(209, 2, 26, 0.5);

    /* Secondary Button */
    --secondary-button-text-color: #2961fd;
    --secondary-button-color: #ffffff;
    --secondary-button-hover-color: #f7f9ff;
    --secondary-button-active-color: #e8eeff;
    --secondary-button-deactivated-color: #ffffff;

    --green-button-text-color: #ffffff;
    --green-button-color: #238f55;
    --green-button-hover-color: #238f55;
    --green-button-active-color: #238f55;
    --green-button-deactivated-color: #238f55;

    /* TextFields */
    /* Material design specifies 54% for secondary text. 
       We are going 60%. You change this, you get to 
       explain it to Melissa. */
    --default-label-color: rgba(0, 0, 0, 0.60);
    --placeholder-text-color: rgba(0, 0, 0, 0.87);
    --required-textfield-color: #d0021b;
    --required-dropdown-color: #d0021b;

    /* Checkboxes */
    --primary-checkbox-color: #2961fd;

    /* Sections */

    --primary-section-title-color: #2961fd;

    /* cards */

    --summary-card-title-color: white;

    /* Status */
    --status-green-1: #417505;
    --status-green-2: #6dbe10;
    --status-green-3: #19C922;

    --status-grey-1: #a1a1a1;
    --status-grey-2: #4a4a4a;

    --status-yellow-2: #c2b61b;

    /* Error box */
    --error-container-border-color: #d0021b;
    --error-container-background-color: #f2dede;
    --error-container-text-color: #d0021b;

    /* Extensions */

    --extension-pending-color: #c2b61b;
    --extension-approved-color: #417504;
    --extension-denied-color: #d0021b;
}

.placeholder {
    color: rgba(0, 0, 0, 0.40);
}

.tabs-root {
    background-color: #4c7bfc;
    height: 56px;
}

.tab-indicator-color {
    background-color: #4c7bfc !important; 
}

.tab-root {
    color: var(--primary-button-text-color) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.75 !important;
    letter-spacing: 0.5px !important; 
}

.tab-root-disabled {
    color: rgba(255, 255, 255, 0.38) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.75 !important;
    letter-spacing: 0.5px !important; 
}

.tab-selected > span:first-child {
    width: unset;
    background-color: rgba(255, 255, 255, 0.87);;
    color: #4c7bfc !important;
    border-radius: 8px;
    min-width: 158px
}

.app-header {
    height: 56px;
    background-color: var(--primary-background-color);
    width: 100%;
    display: flex;
    flex-direction: row;
    color: var(--primary-icon-color);
}

.app-home {
    width: calc(100% - 288px);
    margin-left: 288px;
}

/* .splash-screen {
    background-color: var(--secondary-background-color);
} */

.icon {
    color: var(--primary-icon-color);
    width: 24px;
    height: 24px;
}


.icon-2 {
    color: var(--secondary-icon-color);
    width: 24px;
    height: 24px;
}

.primary-icon {
    color: var(--primary-background-color);
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.spacer-20 {
    width: 20px;
}

.spacer-10 {
    width: 10px;
}

.opacity-54 {
    opacity: 0.54;
}

.opacity-87 {
    opacity: 0.87;
}

.no-opacity-important {
    opacity: 1 !important;
}

.no-opacity-important-childs {
    opacity: 1 !important;
}

.title-54 {
    font-size: 12px;
    opacity: 0.54;
}

.title-54-18 {
    font-size: 18px;
    opacity: 0.54;
}

.title-54-16 {
    font-size: 16px;
    opacity: 0.54;
}

.title-54-14 {
    font-size: 14px;
    opacity: 0.54;
}


.title-87-24 {
    font-size: 24px;
    opacity: 0.87;
}

.title-87-16 {
    font-size: 16px;
    opacity: 0.87;
}

.truncate {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-3d-gauge svg{
    height: 210px;
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    height: 48px;
}

.title-container {
    font-size: 18px;
    margin-left: 28px;
}

.title-big {
    font-size: 16px;
    line-height: 1.2;
    text-align: left;
}

.title-small {
    font-size: 12px;
    line-height: 1.2;
    text-align: left;
}

.full-height {
    height: 100%;
}

.height-10 {
    height: 10px;
}

.height-16 {
    height: 16px;
}

.height-20 {
    height: 20px;
}

.height-32 {
    height: 32px;;
}

.form-shell {
    padding: 10px;
}

.vertical-center {
    align-items: center;
    justify-content: center;
}

.only-vertical-center {
    justify-content: center;
}

.text-center {
    text-align: center;
}

.line-height-15 {
    line-height: 1.5;
}

.container-overflow-y {
    overflow-y: auto;
}

.required {
    color: var(--required-textfield-color);
}

.required::after{
    content: "●";
    width:6px;
    height:6px;
    margin-left:-2px;
    color: var(--required-textfield-color);
}

.section-title {
    opacity: 0.87;
    font-size: 14px;
    height: 34px;
    font-weight: bold;
    text-align: left;
    /* margin: 0 6px; */
    text-transform: uppercase;
    color: var(--primary-section-title-color);
}

.title-divider {
    height: 2px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.38);
}

.form-container {
    padding: 16px;
}

.form-margin {
    margin-left: 6px; 
    margin-right: 6px; 
}

.form-margin-16 {
    margin-left: 16px; 
    margin-right: 16px; 
}

.form-margin-10 {
    margin-left: 10px; 
    margin-right: 10px; 
}

.margin-16 {
    margin: 16px;
}

.margin-10 {
    margin: 10px;
}

.form-padding-16 {
    padding-left: 16px; 
    padding-right: 16px; 
}

.form-paper-input {
    margin: 0 10px;
}

.application-status-icon {
  width: 84px;
  height: 84px;
}

.application-status-title {
    font-size: 40px;
    line-height: 0.6;
    color: rgba(0, 0, 0, 0.87);
}

.application-status-title-small {
    font-size: 24px;
    line-height: 0.6;
    color: rgba(0, 0, 0, 0.87);
}


.application-subheader {
    background-color: #e9ebf2;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    border-top: solid 1px #d6d6d6;
    border-left: solid 1px #d6d6d6;
    border-right: solid 1px #d6d6d6;
} 

.application-subheader-cells {
    height: 24px;
    border-right: solid 1px #d6d6d6;
    border-bottom: solid 1px #d6d6d6;
    background-color: #e9ebf2;
    min-width: 350px;
    -ms-flex: 1 1 0.000000001px;
    -webkit-flex: 1;
    flex: 1;
}

.application-subheader-cell, .application-subheader-cell-error {
    min-width: 50%;
    padding-left: 16px;
    background-color: #e9ebf2;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-left: 1px solid #d6d6d6;
}

.application-subheader-cell-error {
    letter-spacing: 0;
    color: #d0021b;
    font-size:17px;
    font-weight: 500;
}

.application-cert-checkbox {
    margin-top: -8px;
    margin-left: 6px;
    margin-bottom: 0;
    margin-right: 0;
}

.application-narrative-label {
    font-size: 16px;
    font-weight: normal;
    white-space: nowrap;
    color: rgba(0,0,0,0.60);
}

.application-narrative-label-error {
    color: #d0021b;
    font-size: 29px;
    white-space: nowrap;
    font-weight: bold;
}

.green-cirle-check {
    width: 20px;
    height: 20px;
    color: #417505;
}

.remove-outline {
    width: 20px;
    height: 20px;
}

.color-green {
    color: #238f55;
}

.dialog-container {
    width: 400px;
    height: 400px;
}

.contact-support-dialog-container {
    width: 340px;
    height: 280px;
}

.material-radio {
    margin: 0 16px !important;
}

.box-shadow {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.cursor-pointer {
    cursor: pointer;
}

#appSideDrawer {
    width: 288px;
}

.upload-card-margin {
    margin: 10px;
    margin-left: 0;
}

/* Errors */

.error-msg-container {
    border-radius: 2px;
    border: solid 1px var(--error-container-border-color);
    background-color: var(--error-container-background-color);
    color: var(--error-container-text-color);
    line-height: 1.31;
    font-size: 16px;
}

.error-container-margin {
    margin-left: 10px;
}

.error-container-padding {
    padding: 10px;
}

.financial-item-container {
    height: 56px;
    border-bottom: 1px solid #E0E0E0;
}

.financial-item-title {
    font-size: 16px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.54);
}

/* Informational Cards styling */

.informational-cards-container {
    height: 56px;
    background-color: #f4f5f8;
}

.informational-cards-container-mobile {
    height: 300px;
    width: 162px;
    padding: 0;
    background-color: #f4f5f8;
}

.informational-card {
    padding: 8px;
}

.informational-card-title {
    font-size: 10px;
    letter-spacing: 0.4px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
}

.informational-card-amount-1 {
    font-size: 24px;
    text-align: center;
    margin-top: 4px;
}

.informational-card-amount-2 {
    font-size: 12px;
    font-weight: 300;
    margin-top: 4px;
}

.information-card-vertical-line {
    background-color: #000000;
    margin: 8px 0; 
    width: 1px;
}

.information-card-horizontal-line {
    background-color: #000000;
    margin: 0 8px; 
    height: 1px;
}

.informational-card-text-color-safe {
    color: #000000;
}

.informational-card-text-color-danger {
    color: #990013;
}

.informational-card-text-color-info {
    color: #590056;
}


/* Grid styling */

.ReactTable {
    padding: 0;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    padding: 0 !important;
}

/* Row highlighting */
.ReactTable .rt-table .rt-tbody .rt-tr-group:hover {
	 background: rgba(0, 0, 0, 0.05);
}


.body-cell {
    height: 43px;
    padding-left: 18px !important;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    /* color: var(--primary-text-color)  !important; */
    /* background-color: #ffffff !important; */
    cursor: pointer !important;
    
    border-bottom: 1px solid #E0E0E0;
}

.body-header {
    padding-left: 18px;
    font-size: 16px !important;
    background: #f5f5f5 !important;
    font-weight: 500 !important;
    min-height: 42px !important;
    line-height: 0;
    box-sizing: border-box;
    box-shadow: 0 -1px 0 #e0e0e0 inset !important;
}

/* .rt-thead {
    box-sizing: border-box;
} */


/* Global tag styling */

a {
    text-decoration: none !important;
}

button > span > a {
    display: block;
    height: 100%;
    width: 100%;
    color: inherit !important;
}

input[type="file"] {
    visibility: hidden;
    width: 0;
    height: 0;
}

.login-error-msg {
    max-width: 260px;
    text-align: center;
}

.return-to-login {
    color: var(--primary-background-color) !important;
    font-size: 18px;
}

.award-letters-download-box {
    width: 700px;
}

.check-details-box {
    width: 400px;
}

.award-file-title {
    font-size: 16px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.87);
}

.award-file {
    padding: 8px 0px;
    height: 56px;
    border-bottom: 1px solid #E0E0E0;
}

@media screen and (max-width: 768px) {
    .app-home {
        width: 100%;
        margin-left: 0;
    }
    .dialog-container {
        width: 100vw;
        height: 100vh;
        max-height: 100% !important;
        margin: 0 !important;
    }

    .award-letters-download-box {
        padding: 0 16px;
        width: 100%;
    }

}



.card-container:hover {
  background: rgba(0, 0, 0, 0.05);
}

.card-container-3r-1c {
    height: 82px;
    padding: 0 16px;
    border-bottom: 1px solid #E0E0E0;
}

.set-width {
  width: 220px;
}

.card-container-2r-1c {
    height: 64px;
    padding: 0 16px;
    border: 1px solid #E0E0E0;
}

.summary-cards {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  display: inline-block;
}

.summary-card {
  height: 64px;
  min-width: 132px;
  border-radius: 2px;
}

.exec-summary-card {
  height: 200px;
  min-width: 288px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

/* .card-text- */

.card-slot-1r {
  opacity: 0.87;
  font-size: 16px;
  font-weight: bold;
  color: var(--primary-text-color);
}

.card-slot-2r {
  /* opacity: 0.54; */
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-text-color);
}

.card-slot-3r {
  opacity: 0.54;
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-text-color);
}

.summary-card-text-container {
  height: 40px;
  justify-content: center;
  padding: 0px;
  align-items: center;
}

.summary-card-text-divider {
  border-left: 1px solid rgba(0,0,0,0.25);
}

.summary-card-title-divider {
  border-left: 1px solid white;
}

.summary-card-text {
  opacity: 0.87;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  text-align: center;
}

.summary-card-title-container {
  background-color: var(--primary-background-color)
}

.summary-card-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 1.3px;
  color: var(--summary-card-title-color);
}


.exec-summary-card-text-container {
  height: 150px;
  justify-content: center;
  align-items: center;
}

.exec-summary-card-text {
  opacity: 0.54;
  font-size: 48px;
  font-weight: 300;
}

.card-spacer {
  width: 20px;
}

.card-in-progress {
  background-color: var(--status-green-2);
}
.card-grey-1 {
  background-color: var(--status-grey-1);
}

.card-submitted {
  background-color: var(--status-yellow-2);
}

.card-awarded {
  background-color: var(--status-green-1);
}

.card-shell {
  margin: 8px;
}

/* Small screen styling */

@media screen and (max-width: 768px) {

  .card-container {
    max-width: 100%;
  }

  .summary-cards {
    display: block;
  }

  .summary-card {
    width: 100%;
    max-width: 100%;
  }
}

